import { ApiResponse, zps_ea_war } from '../zps';
import axios from 'axios';

const SHOPS_BASE_URL = `${zps_ea_war}/shop`;

export type IShop = {
  description: string;
  entid: number;
  gid: number;
  name: string;
  sid: number;
};

export interface IShopResponse extends ApiResponse {
  shops: IShop[];
  type: string;
}

export const fetchShops = async () => {
  const response = await axios.get(SHOPS_BASE_URL);
  return response.data;
};
