import React from 'react';
import { Group, PasswordInput, TextInput } from '@mantine/core';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';

interface EmailLoginFormProps {
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  email: string | undefined;
  password: string | undefined;
}

const EmailLoginForm = ({ onEmailChange, onPasswordChange, email, password }: EmailLoginFormProps) => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  return (
    <>
      <Group >
        <TextInput
          label={lang?.USER_EMAIL}
          placeholder=""
          value={email || ''}
          onChange={onEmailChange}
        />
      </Group>
      <Group >
        <PasswordInput
          label={lang?.GLOBAL_PASSWORD}
          placeholder=""
          value={password || ''}
          onChange={onPasswordChange}
        />
      </Group>
    </>
  );
};

export default EmailLoginForm;
