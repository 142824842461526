import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';

export type IAPMTransaction = {
  key: APMTransKey;
  gid: number;
  entid: number;
  sid: number;
  amount: number; 
  bankid: number;
  cid: number;
  dateTime: string;
};

export type IAPMPeriods = {
  key: APMTransKey;
  gid: number;
  entid: number;
  sid: number;
  amount: number;
  amountCommitions: number;
  amountWithoutCommitions: number; 
  bankid: number;
  cid: number;
  dateTime: string;
totalTransactions: number;
};

export type IAPMPComissions = {
  key: APMTransKey;
  a052: number;
  a054:string;
  amount: number;
  amountCommission: number;
  cid: number;
  pCommission: string;
};

export type APMTransKey = {
  a001: number;
  a029: number;
  a031: number;
  a032: number;
  dateTime: string;
  movType: string;
};

export interface IAPMTransactionResponse extends ApiResponse {
  payment: boolean;
  totalRows: number;
  transactions: IAPMTransaction[];
  type: string;
};

export interface IAPMPeriodsResponse extends ApiResponse {
  payment: boolean;
  totalRows: number;
  periods: IAPMPeriods[];
  type: string;
  commissions: IAPMPComissions[];
};

export type IAPMPos = {
  description: string,
    entid: number,
    posid: number,
    sid: number,
};

export interface fetchApmPOSEquipmentListResponse extends ApiResponse {
  poss: IAPMPos[];
};

export type apmEquipPos = {
  account: number;
active: boolean;
bankid: number;
description: string;
eid?: number;
entid: number;
gid: number;
inuse: number;
mbway: boolean;
merchantid: number;
posid: number;
poskey?: string;
sid: number;
timeopenclose?: number;
zsversion: number;
}

export type IAPMPosEquipment = {
  eid?: number,
lastComDt?: string,
lastOperation?: string,
lastOperationDt?: string,
status?: number,
pos:apmEquipPos;
};

export interface fetchPOSEquipmentDetailsResponse extends ApiResponse {
  poss: IAPMPosEquipment[];
  totalRows: number;
}

export const fetchAPMTransactionList = async (
    begin: string,
    end: string,
    entid: number,
    sid: number,
    posid: number,
    offset: number,
    limit: number,
    totalRows: boolean,
    controller: AbortController
  ) => {
    const { data } = await axios.get(
      `${zps_ea_war}/apm/transaction?begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&posid=${posid}&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
      {
        signal: controller.signal
      }
    );
    return data;
  };

  export const fetchApmPOSEquipmentList = async (
    entid: number,
    sid: number,
    controller: AbortController
  ) => {
    const { data } = await axios.get(
      `${zps_ea_war}/apm/pos/label/?entid=${entid}&sid=${sid}`,
      {
        signal: controller.signal
      });
    return data;
  };
  
  
  export const fetchAPMPeriodsList = async (
    begin: string,
    end: string,
    entid: number,
    sid: number,
    posid: number,
    offset: number,
    limit: number,
    totalRows: boolean,
    controller: AbortController
  ) => {
    const { data } = await axios.get(
      `${zps_ea_war}/apm/period/?begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&posid=${posid}&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
      {
        signal: controller.signal
      }
    );
    return data;
  };

  export const fetchReceiptData = async (
    a001: number,
    movType: string,
    a031: number,
    a032: number,
    a029: number,
    dateTime: string,
    controller: AbortController
  ) => {
  
    const {data} = await axios.get(
      `${zps_ea_war}/apm/receipt/?a001=${a001}&movType=${movType}&a031=${a031}&a032=${a032}&a029=${a029}&dateTime=${ new Date(dateTime).toISOString()}`,
    {
      signal:controller.signal
    });
    return data;
  };

  export const fetchComissionsData = async (
    a001: number,
    movType: string,
    a031: number,
    a032: number,
    a029: number,
    dateTime: string,
    controller: AbortController
  ) => {
  
    const {data} = await axios.get(
      `${zps_ea_war}/apm/period/commission/?a001=${a001}&movType=${movType}&a031=${a031}&a032=${a032}&a029=${a029}&dateTime=${ new Date(dateTime).toISOString()}`,
    {
      signal:controller.signal
    });
    return data;
  };

  export const fetchTransactionData = async (
    a001: number,
    movType: string,
    a031: number,
    a032: number,
    a029: number,
    dateTime: string,
    controller: AbortController
  ) => {
  
    const {data} = await axios.get(
      `${zps_ea_war}/apm/period/transaction/?a001=${a001}&movType=${movType}&a031=${a031}&a032=${a032}&a029=${a029}&dateTime=${ new Date(dateTime).toISOString()}`,
    {
      signal:controller.signal
    });
    return data;
  };

  export const fetchPOSEquipmentDetails = async (
    entid: number,
    sid: number,
    offset: number,
    posid: number,
    limit: number,
    totalRows: boolean,
    controller: AbortController
  ) => {
  
    const {data} = await axios.get(
      `${zps_ea_war}/apm/pos/details/?entid=${entid}&sid=${sid}&offset=${offset}&posid=${posid}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal:controller.signal
    });
    return data;
  };

  export const updateApmEquipmentDetails = async (
    posid: number,
    timeopenclose: number,
    description: string,
    controller: AbortController
  ) => {
    const url = `${zps_ea_war}/apm/pos/details/`;
  
    const { data } = await axios.put(url, {
      posid,
      timeopenclose,
      description,
    }, {
      signal: controller.signal,
    });
  
    return data;
  };
  