import axios from 'axios';
import store from '../app/client-redux/store';

const axiosConfig = () => {
  axios.interceptors.request.use(
    function (config) {
      const token = store.getState().login?.token;
      // console.log('axios.interceptors current store: store.getState().login', store.getState()?.login);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      // console.log('Interceptor promise error: ', error);
      return Promise.reject(error);
    }
  );
};

export default axiosConfig;
