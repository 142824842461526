import moment from 'moment';
import { useEffect, useState } from 'react';
import { Loader, Container, Group, Table } from '@mantine/core';
import Header from '../../../components/util/header';
import { useSelector } from 'react-redux';
import { fetchPOSEquipmentDetails, fetchPOSEquipmentDetailsResponse } from '../../../../libraries/apm';
import { handleEntid, handleShortTransactionType } from '../../../utils/script';
import { AppState } from '../../../../app/client-redux/reducers';
import { Language } from '../../../languages/languageHandler';
import PaginationComponent from '../../../components/pagination';
import EditEquipmentPos from '../../../components/edit-equipment-pos';
import { LoginState } from '../../../client-redux/login/reducer';
import { IShop } from '../../../../libraries/shop';
import { IEntity } from '../../../../libraries/entity';
import FilterBar from '../../../components/filterbar';
import './styles.scss';

const APMEquipments = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const shops = useSelector<AppState, IShop[]>(state => state.shops.shops);
  const entities = useSelector<AppState, IEntity[]>(state => state.entities.entities);
  const [loading, setLoading] = useState(false);
  const [entid, setEntid] = useState<number>(loginInfo.user?.entid);
  const [sid, setSid] = useState<number>(0);
  const [posid, setPosid] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  /* const itemsPerPage = window.innerHeight < 600 ? 5 : Math.floor(window.innerHeight / 55 - 2); */
  const [limit, setLimit] = useState<number>(10);
  const [, setCurrentPage] = useState<number>(0);
  const [message, setMessage] = useState<fetchPOSEquipmentDetailsResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      getApmEquipList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const onSearch = () => {
    setSearchTrigger(true);
  };

  const getApmEquipList = () => {
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);
    fetchPOSEquipmentDetails(entid, sid, offset, posid, limit, totalRows, controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          // console.log('API request aborted');
        } else {
          // console.error('API request error:', error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updatePosid = (newPosid: number, callback?: () => void) => {
    setPosid(newPosid);
    if (callback) {
      callback();
    }
  };

  const updateTablePosList = (newPosid: number, callback?: () => void) => {
    updatePosid(newPosid);
    if (callback) {
      callback();
    }
  };

  const updateSid = (newSid: number, callback?: () => void) => {
    setSid(newSid);
    if (callback) {
      callback();
    }
  };

  const updateTableSid = (newSid: number, callback?: () => void) => {
    updateSid(newSid);
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    getApmEquipList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, sid, posid]);

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  const checkStatusForCell = (statusCode?: number | null) => {
    if (statusCode !== undefined && statusCode !== null) {
      if (statusCode === 4) {
        return <div className="offlineStatus">Offline</div>;
      } else if (statusCode === 0) {
        return '';
      } else {
        return <div className="onlineStatus">Online</div>;
      }
    }
  };

  const checkEquipmentEid = (eid?: number | null) => {
    if (eid !== undefined && eid !== null && eid > 0) {
      return eid;
    } else {
      return '';
    }
  };

  const checkTimeOpenCloseforCell = (timeCode?: number | null) => {
    if (timeCode !== undefined && timeCode !== null && timeCode > 0) {
      const hours = Math.floor(timeCode / 60);
      const minutes = timeCode % 60;
      const formattedHours = hours.toString().padStart(2, '00');
      const formattedMinutes = minutes.toString().padStart(2, '00');
      return `${formattedHours}:${formattedMinutes}`;
    }
    return '';
  };

  return (
    <Container fluid classNames={{ root: 'APMEquipments' }} >
      <Header pageTitle="POS" />

      <FilterBar
        onSearch={onSearch}
        listEntid={updateTableEntid}
        listLoaded={getApmEquipList}
        listSid={updateTableSid}
        listPosId={updateTablePosList}
      />

      <Table.ScrollContainer minWidth={500}>
        <Table striped verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{lang?.GLOBAL_ENTITY}</Table.Th>
              <Table.Th>POS</Table.Th>
              <Table.Th>{lang?.EQUIPMENT_DESCRIPTION}</Table.Th>
              <Table.Th>{lang?.USER_DELEGATION}</Table.Th>
              <Table.Th>{lang?.ENTERPRISE_EQUIP_PERIODS_DT_CLOSE}</Table.Th>
              <Table.Th>{lang?.GLOBAL_EQUIPMENT}</Table.Th>
              <Table.Th>{lang?.EQUIPMENT_COM_STATE}</Table.Th>
              <Table.Th>{lang?.EQUIPMENT_COM_DATE}</Table.Th>
              <Table.Th>{lang?.EQUIPMENT_LAST_OPER}</Table.Th>
              <Table.Th>{lang?.EQUIPMENT_LAST_OPER_DT}</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {loading && (!message || searchTrigger) ? (
              <Table.Tr>
                <Table.Td colSpan={16}>
                  <Loader color="#016273" />
                </Table.Td>
              </Table.Tr>
            ) : (
              <>
                {noResults ? (
                  <Table.Tr>
                    <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                      {lang?.GLOBAL_NO_RESULTS}
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  message?.poss?.map((pos, index) => {
                    const matchingShop = shops.find(shop => shop.sid === pos.pos.sid);
                    return (
                      <Table.Tr key={index}>
                        <Table.Td>{handleEntid(entities, pos.pos.entid)}</Table.Td>
                        <Table.Td>{pos.pos.posid}</Table.Td>
                        <Table.Td>{pos.pos.description}</Table.Td>
                        <Table.Td>{matchingShop ? matchingShop.name : ''}</Table.Td>
                        <Table.Td>{checkTimeOpenCloseforCell(pos.pos.timeopenclose)}</Table.Td>
                        <Table.Td>{checkEquipmentEid(pos.pos.eid)}</Table.Td>
                        <Table.Td>{checkStatusForCell(pos.status)}</Table.Td>
                        <Table.Td>{moment(pos.lastComDt).format('YYYY-MM-DD HH:mm:ss')}</Table.Td>
                        <Table.Td>{handleShortTransactionType(lang, pos.lastOperation)}</Table.Td>
                        <Table.Td>{moment(pos.lastOperationDt).format('YYYY-MM-DD HH:mm:ss')}</Table.Td>
                        <Table.Td>
                          <EditEquipmentPos
                            posid={pos.pos.posid}
                            timeopenclose={pos.pos.timeopenclose || 0}
                            description={pos.pos.description}
                            update={getApmEquipList}
                          />
                        </Table.Td>
                      </Table.Tr>
                    );
                  })
                )}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>

      <Group>
        <PaginationComponent
          total={message?.totalRows}
          statusOperCode={statusOperCode}
          itemsPerPage={limit}
          onPageChange={changePage}
          onResultsChange={resultsSelect}
        />
      </Group>
    </Container>
  );
};

export default APMEquipments;
