import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './app/pages/login';
import Equipment from './app/pages/equipment';
import Transactions from './app/pages/transactions';
import Users from './app/pages/users';
import Profile from './app/pages/profile';
import { useSelector } from 'react-redux';
import { AppState } from './app/client-redux/reducers';
import axiosConfig from './libraries/axiosConfig';
import { WithNavBar } from './app/components/nav-bar/with-nav-bar';
import EnterpriseEquipPeriods from './app/pages/enterprise-equip-periods';
import BankTransactions from './app/pages/bank-transactions';
import Dashboard from './app/pages/dashboard';
import APMTransactions from './app/pages/apm/transactions';
import APMPeriods from './app/pages/apm/periods';
import APMEquipments from './app/pages/apm/equipments';
import './app.scss';

const App = () => {
  const token = useSelector<AppState, String | undefined>(state => state.login.token);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  useEffect(() => {
    // console.log('refreshed');
    axiosConfig();
  }, []);

  const protectedRoute = (element: JSX.Element) => {
    return token ? element : <Navigate to="/" />;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/dashboard"
            element={protectedRoute(
              <WithNavBar>
                <Dashboard />
              </WithNavBar>
            )}
          />
          <Route
            path="/equipment"
            element={protectedRoute(
              <WithNavBar>
                <Equipment />
              </WithNavBar>
            )}
          />
          <Route
            path="/transactions"
            element={protectedRoute(
              <WithNavBar>
                <Transactions />
              </WithNavBar>
            )}
          />
          <Route
            path="/users"
            element={protectedRoute(
              <WithNavBar>
                <Users />
              </WithNavBar>
            )}
          />
          <Route
            path="/enterpriseEquiPeriods"
            element={protectedRoute(
              <WithNavBar>
                <EnterpriseEquipPeriods />
              </WithNavBar>
            )}
          />
          <Route
            path="/bankTransactions"
            element={protectedRoute(
              <WithNavBar>
                <BankTransactions />
              </WithNavBar>
            )}
          />

          <Route
            path="/apmTrasactions"
            element={protectedRoute(
              <WithNavBar>
                <APMTransactions />
              </WithNavBar>
            )}
          />

          <Route
            path="/apmPeriods"
            element={protectedRoute(
              <WithNavBar>
                <APMPeriods />
              </WithNavBar>
            )}
          />

          <Route
            path="/apmEquipments"
            element={protectedRoute(
              <WithNavBar>
                <APMEquipments />
              </WithNavBar>
            )}
          />

          <Route
            path="/profile"
            element={protectedRoute(
              <WithNavBar>
                <Profile />
              </WithNavBar>
            )}
          />

          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};

export default App;
