import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';

const ENTITY_BASE_URL = `${zps_ea_war}/entity`;

export type IEntity = {
  entid: number;
  gid: number;
  lang: number;
  name: string;
  ownerid: number;
  printType: number;
  region: string;
  reportType: number;
  type: number;
  usePos: number;
  useShifts: number;
};

export interface IEntityResponse extends ApiResponse {
  entities: IEntity[];
  type: string;
}

export const fetchEntities = async () => {
  const response = await axios.get(`${ENTITY_BASE_URL}`);
  return response.data;
};
