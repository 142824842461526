import { useEffect, useState } from 'react';
import { Button, Checkbox, Container, Grid, GridCol, Group, PasswordInput, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IUser, PermissionOption } from '../../../libraries/users/index';
import { fetchRegions } from '../../../libraries/region/index';
import { ILanguage, fetchLanguages } from '../../../libraries/language/index';
import { Language } from '../../languages/languageHandler';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { LoginState } from '../../client-redux/login/reducer';
import './styles.scss';

interface FormProps {
  isNew: boolean;
  user: IUser;
  submitForm: (data?: any) => void;
  cancelBtn: () => void;
  lang: Language;
  permissionOptions: PermissionOption[];
  togglePermissions: (permissions: PermissionOption) => void;
}

const UserForm = ({ isNew, user, submitForm, /* cancelBtn */ lang, permissionOptions, togglePermissions }: FormProps) => {
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const [selectedPermissions, setSelectedPermissions] = useState<PermissionOption[]>(permissionOptions);
  const [regions, setRegions] = useState<string[]>([]);
  const [languages, setLanguages] = useState<ILanguage[]>([]);

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      entid: user?.entid || loginInfo.user?.entid,
      uid: user?.uid,
      name: user?.name,
      mobile: user?.mobile,
      email: user?.email,
      card: user?.card,
      lang: user?.lang || loginInfo.user?.lang,
      region: user?.region || loginInfo.entity?.region,
      password: user?.password,
      passwordConfirm: '',
      pin: user?.pin,
      pinConfirm: '',
      permissions: user?.permissions || []
    },
    validate: {
      passwordConfirm: value => (value === user?.password ? `${lang.USER_NEW_PASSWORD_ERROR}` : null),
      pinConfirm: value => (value === user?.pin ? `${lang.USER_NEW_PIN_ERROR}` : null)
    }
  });

  useEffect(() => {
    setSelectedPermissions(
      permissionOptions.map(permission => ({
        ...permission,
        selected:
          user.permissions?.some(p => p.appId === permission.appId && (p.permissionCode & permission.mask) !== 0) ||
          false
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    fetchRegions().then(response => {
      setRegions(response.regions);
    });
    fetchLanguages().then(response => {
      setLanguages(response.languages);
    });
  }, []);

  const getPermissionLabel = (langKey: string, lang: any) => {
    return lang[langKey];
  };

  const toggleSelection = (permission: PermissionOption, index: number) => {
    if (!permission) {
      return null;
    } else {
      const updatedPermissions = [...selectedPermissions];
      updatedPermissions[index] = {
        ...permission,
        selected: !selectedPermissions[index].selected
      };
      setSelectedPermissions(updatedPermissions);
      togglePermissions(permission);
    }
  };

  return (
    <Container>
      <form
        onSubmit={form.onSubmit(values => {
          submitForm(values);
        })}
        className="userForm"
      >
        <Grid>
          <GridCol>
            <h3>{lang?.USER_INFO}</h3>
          </GridCol>
        </Grid>

        {/* Entity */}
        <Group>
          <Grid>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <TextInput
                label={lang?.USER_ENTITY}
                placeholder={isNew ? lang?.USER_ENTITY_PLACEHOLDER : ''}
                type="text"
                {...form.getInputProps('entid')}
              ></TextInput>
            </GridCol>

            {/* ID */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <TextInput
                label={lang?.USER_UID}
                placeholder={isNew ? lang.USER_ID_PLACEHOLDER : ''}
                type="text"
                required={true}
                {...form.getInputProps('uid')}
              ></TextInput>
            </GridCol>
          </Grid>
        </Group>

        {/* Name */}
        <Group>
          <Grid>
            <GridCol span={{ base: 12, md: 12, lg: 12 }}>
              <TextInput
                label={lang?.GLOBAL_NAME}
                placeholder={isNew ? lang.USER_NAME_PLACEHOLDER : ''}
                type="text"
                {...form.getInputProps('name')}
              ></TextInput>
            </GridCol>
          </Grid>
        </Group>

        <Group>
          <Grid>
            {/* Mobile */}
            <GridCol span={{ base: 4, md: 6, lg: 4 }}>
              <TextInput
                label={lang?.USER_MOBILE}
                placeholder={isNew ? lang.USER_MOBILE_PLACEHOLDER : ''}
                type="text"
                {...form.getInputProps('mobile')}
              ></TextInput>
            </GridCol>

            {/* Email */}
            <GridCol span={{ base: 4, md: 6, lg: 4 }}>
              <TextInput
                label={lang?.USER_EMAIL}
                placeholder={isNew ? lang.USER_EMAIL_PLACEHOLDER : ''}
                type="email"
                {...form.getInputProps('email')}
              ></TextInput>
            </GridCol>

            {/* Card */}
            <GridCol span={{ base: 4, md: 12, lg: 4 }}>
              <TextInput
                label={lang.USER_CARD}
                placeholder={isNew ? lang.USER_CARD_PLACEHOLDER : ''}
                type="text"
                {...form.getInputProps('card')}
              ></TextInput>
            </GridCol>
          </Grid>
        </Group>

        {/* Language */}
        <Group>
          <Grid>
            <GridCol>
              <Select
                label={lang?.USER_LANG}
                {...form.getInputProps('lang')}
                value={form.values.lang?.toString()}
                data={[
                  ...(languages?.map(language => ({
                    value: language.langid.toString(),
                    label: language.nativeName
                  })) || [])
                ]}
              />
            </GridCol>

            {/* Region */}
            <GridCol>
              <Select
                label={lang?.USER_REGION}
                {...form.getInputProps('region')}
                data={[
                  ...(regions?.map(region => ({
                    value: region,
                    label: region
                  })) || [])
                ]}
                required={true}
                searchable
              />
            </GridCol>
          </Grid>
        </Group>

        {/* Password */}
        <Group>
          <Grid>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <PasswordInput
                label={lang?.GLOBAL_PASSWORD}
                placeholder={isNew ? lang?.USER_CHANGE_PASSWORD : ''}
                {...form.getInputProps('password')}
              />
            </GridCol>

            {/* Confirm password */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <PasswordInput
                label={lang?.USER_CONFIRM_PASSWORD}
                placeholder={isNew ? lang?.USER_CONFIRM_PASSWORD : ''}
                {...form.getInputProps('passwordConfirm')}
              />
            </GridCol>
          </Grid>
        </Group>

        {/* Pin */}
        <Group>
          <Grid>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <PasswordInput
                label={lang?.USER_PIN}
                placeholder={isNew ? lang?.USER_CHANGE_PIN : ''}
                {...form.getInputProps('pin')}
              />
            </GridCol>

            {/* Confirm Pin */}
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <PasswordInput
                label={lang?.USER_CONFIRM_PIN}
                placeholder={isNew ? lang?.USER_CONFIRM_PIN : ''}
                {...form.getInputProps('pinConfirm')}
              />
            </GridCol>
          </Grid>
        </Group>

        {/* Permissions */}
        <Grid>
          <GridCol span={{ base: 6, md: 6, lg: 12 }}>
            <h3>{lang?.USER_PERMISSIONS}</h3>
          </GridCol>
        </Grid>
        {permissionOptions.map((permission, index) => (
          <Group key={index}>
            <Grid>
              <GridCol>
                <Checkbox
                  label={getPermissionLabel(permission.langKey, lang)}
                  checked={selectedPermissions[index].selected}
                  onChange={() => toggleSelection(permission, index)}
                />
              </GridCol>
            </Grid>
          </Group>
        ))}

        <Group>
          <Button color="#016273" radius="md" type="submit">
            {lang?.GLOBAL_SAVE_BUTTON}
          </Button>
          {/*  <Button color="#016273" radius="md" onClick={cancelBtn}>
            {lang?.GLOBAL_CANCEL_BUTTON}
          </Button> */}
        </Group>
      </form>
    </Container>
  );
};

export default UserForm;
