import moment from 'moment';
import { Container, Group, GridCol, Grid, Button, useMantineColorScheme, Title } from '@mantine/core';
import { ITransaction } from '../../../libraries/transactions';
import { Language } from '../../languages/languageHandler';
import { capitalizeWords, formatAmount2, handleTransactionType } from '../../utils/script';
import ModalComponent from '../modal';
import CurrencyTable from '../util/currency-table';
import { ICurrency } from '../../../libraries/currencies';
import './styles.scss';

interface ShowTransactionProps {
  transaction: ITransaction;
  openDetails: boolean;
  hideBtn: () => void;
  lang: Language;
  currency: ICurrency;
  getLog: () => void;
}

const ShowTransaction = ({ transaction, openDetails, hideBtn, lang, currency, getLog }: ShowTransactionProps) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={lang.TRANSACTION_DETAILS}
        size={'xl'}
        content={
          <>
            <Container bg={colorScheme === 'dark' ? '#1b1b1b': 'white'} classNames={{ root: 'transactionDetails' }}>
              <Group>
                <Grid justify="flex-start" align="flex-start" gutter="sm">
                  <GridCol span={{ base: 6, md: 4, lg: 4 }}>
                    <Title order={4}>{lang.USER_DELEGATION}</Title>
                    <GridCol>{transaction.entid}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 6, md: 4, lg: 4 }}>
                    <Title order={4}>{lang.EQUIPMENT_ID}</Title>
                    <GridCol>{transaction.eid}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 6, md: 4, lg: 4 }}>
                    <Title order={4}>{lang.GLOBAL_PERIOD}</Title>
                    <GridCol>{transaction.period}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 6, md: 4, lg: 4 }}>
                    <Title order={4}>{lang.GLASS_CODE}</Title>
                    <GridCol>{transaction.code}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 6, md: 4, lg: 4 }}>
                    <Title order={4}>{lang.GLOBAL_DATE_TIME}</Title>
                    <GridCol>{moment(transaction.dateTime).format('DD-MM-YYYY HH:mm:ss')}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 6, md: 4, lg: 4 }}>
                    <Title order={4}>{lang.GLOBAL_AMOUNT}</Title>
                    <GridCol>{formatAmount2(transaction.value)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 6, md: 4, lg: 4 }}>
                    <Title order={4}>{lang.TRANSACTION_RECEIVED}</Title>
                    <GridCol>{formatAmount2(transaction.valueRec)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 6, md: 4, lg: 4 }}>
                    <Title order={4}>{lang.TRANSACTION_DISPENSED}</Title>
                    <GridCol>{formatAmount2(transaction.valueDis)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 6, md: 4, lg: 4 }}>
                    <Title order={4}>{lang.GLOBAL_TYPE}</Title>
                    <GridCol>{capitalizeWords(transaction.valueType)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 6, md: 4, lg: 4 }}>
                    <Title order={4}>{lang.TRANSACTION_PAYMENT_METHOD}</Title>
                    <GridCol>{handleTransactionType(lang, transaction.type)}</GridCol>
                  </GridCol>
                </Grid>

                {transaction.cashIn && currency && (
                  <>
                    <Title order={4}>{lang.CURRENCY_TABLE_CASH_IN}</Title>
                    <CurrencyTable lang={lang} currency={currency} cash={transaction.cashIn} />
                  </>
                )}

                {transaction.cashOut && currency && (
                  <>
                    <Title order={4}>{lang.CURRENCY_TABLE_CASH_OUT}</Title>
                    <CurrencyTable lang={lang} currency={currency} cash={transaction.cashOut} />
                  </>
                )}

<Button color="#016273" radius="md" onClick={() => getLog()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z" />
                      </svg>
                      Logs
                    </Button>
              </Group>
            </Container>
          </>
        }
      />
    </>
  );
};

export default ShowTransaction;
