import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { IUser, PermissionOption, updateUser } from '../../../libraries/users/index';
import { Language } from '../../languages/languageHandler';
import UserForm from './user-form';
import { permissionsToUser, preparePermissions } from './permissions';
import CustomModal from '../../components/custom-modal';
import './styles.scss';

interface UpdateProps {
  user: IUser;
  closed: () => void;
  updated: () => void;
  lang: Language;
  permissionOptions: PermissionOption[];
}

const UpdateContent = ({ user, closed, updated, lang, permissionOptions }: UpdateProps) => {
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedPermissions, setSelectedPermissions] = useState<PermissionOption[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    preparePermissions(permissionOptions, user);
    setSelectedPermissions(permissionOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const updater = useMutation(updateUser, {
    onSuccess: data => {
      const { statusOper } = data;
      if (statusOper.code === 0) {
        setShowSuccessDialog(true);
        setSuccessMessage(lang?.EDIT_USER_ALERT_UPDATE_SUCCESS);
      } else {
        setShowErrorDialog(true);
        setErrorMessage(lang?.EDIT_USER_ALERT_UPDATE_ERROR + '\n' + statusOper.message);
      }
      updated();
      setTimeout(() => {
        if (showModal) {
          closeModal();
        }
      }, 4000);
    },
    onError: error => {
      setShowErrorDialog(true);
      setErrorMessage(lang?.EDIT_USER_ALERT_UPDATE_ERROR + error);
    }
  });

  const handleSubmit = (updatedUser: IUser) => {
    permissionsToUser(selectedPermissions, updatedUser);
    updater.mutateAsync({ user: updatedUser });
    updated();
  };

  const toggleSelection = (permission: PermissionOption) => {
    setSelectedPermissions(prevPermissions =>
      prevPermissions.map(prevPermission =>
        prevPermission.appId === permission.appId && prevPermission.mask === permission.mask
          ? { ...prevPermission, selected: !prevPermission.selected }
          : prevPermission
      )
    );
  };

  return (
    <>
      <UserForm
        lang={lang}
        isNew={false}
        user={user}
        submitForm={handleSubmit}
        cancelBtn={closed}
        permissionOptions={permissionOptions}
        togglePermissions={toggleSelection}
      />
      <CustomModal
        opened={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        message={successMessage}
        backgroundColor="#3BDF70"
      />

      <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
      />
    </>
  );
};

export default UpdateContent;
