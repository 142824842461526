import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';
import { handleDownload } from '../../app/utils/script';

const EQUIP_PERIOD_BASE_URL = `${zps_ea_war}/tb401/period/`;

export type IPeriodAmount = {
  amount: number;
  amountBilled: number;
  cid: number;
  envTotals: number;
  totalRows: number;
};

export type IPeriod = {
  bankid: number;
  billsInDoubt: number;
  coinsInDoubt: number;
  dtClose: string;
  dtOpen: string;
  eid: number;
  entid: number;
  etvid: number;
  gid: number;
  period: number;
  periodAmounts: IPeriodAmount[];
  periodType: string;
  sid: number;
  state: string;
  uid: number;
  uidClose: number;
  useReport: boolean;
  totalRows: number;
};

export interface IPeriodResponse extends ApiResponse {
  totalRows: number;
  periods: IPeriod[];
}

export const fetchEquipmentPeriodsSummaryResult = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController
) => {
  const { data } = await axios.get(
    `${EQUIP_PERIOD_BASE_URL}?begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    });
  return data;
};

export const fetchEquipmentPeriodReport = async (
  format: string,
  eid: number,
  period: number,
  controller: AbortController
) => {
  try {
    const { data } = await axios.get(`${EQUIP_PERIOD_BASE_URL}/report/?format=${format}&eid=${eid}&period=${period}`, {
      signal: controller.signal
    });

    handleDownload(data.report, data.name, data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};

export const fetchEquipmentPeriodTxt = async (
  format: string,
  eid: number,
  period: number,
  controller: AbortController
) => {
  try {
    const { data } = await axios.get(`${EQUIP_PERIOD_BASE_URL}/report/?format=${format}&eid=${eid}&period=${period}`, {
      signal: controller.signal
    });

    handleDownload(data.report, data.name, data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};
