import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';

export interface LoginBody {
  entid: any;
  uid: any;
  email: any;
  password: string;
}

export interface LoginResponse extends ApiResponse {
  token: string;
  user: any;
}

export const fetchLogin = (body: LoginBody) => {
  return axios.post(`${zps_ea_war}/authenticate`, body);
};
