import moment from 'moment';
import { useEffect, useState } from 'react';
import { Group, Button, Grid, GridCol, Title } from '@mantine/core';
import { fetchEquipmentByEid, IEquipmentDeviceStatus, IEquipmentStatusResponse } from '../../../libraries/equipment';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../modal';
import { handleTransactionType } from '../../utils/script';
import './styles.scss';

interface ShowEquipmentProps {
  eid: number;
  openDetails: boolean;
  hideBtn: () => void;
  lang: Language;
}

const ShowEquipment = ({ eid, openDetails, hideBtn, lang }: ShowEquipmentProps) => {
  const [message, setMessage] = useState<IEquipmentStatusResponse>();
  const [statusList, setStatusList] = useState<IEquipmentDeviceStatus[]>();
  useEffect(() => {
    fetchEquipmentByEid(eid).then(response => {
      setMessage(response);
      setStatusList(response.status.statusList);
    });
  }, [eid]);

  const checkIfOnline = (status?: number) => {
    if (status === 4) {
      return 'Offline';
    } else {
      return 'Online';
    }
  };

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={`EID: ${eid}`}
        size={'xl'}
        content={
          <>
            <Group classNames={{ root: 'showEquipmentDetails' }}>
              <Grid>
                <GridCol  span={{ base: 6, md: 4, lg: 4 }}>
                  <Title order={4}>Status</Title>
                  <GridCol>{checkIfOnline(message?.status.status)}</GridCol>
                </GridCol>

                <GridCol  span={{ base: 6, md: 4, lg: 4 }}>
                  <Title order={4}>Equipment State</Title>
                  <GridCol>{message?.status.inService ? 'Em serviço' : 'Fora de serviço'}</GridCol>
                </GridCol>

                <GridCol  span={{ base: 6, md: 4, lg: 4 }}>
                  <Title order={4}>Last Com. Date</Title>
                  <GridCol>{moment(message?.status.lastComDt).format('DD/MM/YYYY HH:mm:ss')}</GridCol>
                </GridCol>

                <GridCol  span={{ base: 6, md: 4, lg: 4 }}>
                  <Title order={4}>Last Operation</Title>
                  <GridCol>{handleTransactionType(lang, message?.status.lastOperation)}</GridCol>
                </GridCol>

                <GridCol  span={{ base: 6, md: 4, lg: 4 }}>
                  <Title order={4}>Last Operation Date</Title>
                  <GridCol>{moment(message?.status.lastOperationDt).format('DD/MM/YYYY HH:mm:ss')}</GridCol>
                </GridCol>

                <GridCol  span={{ base: 6, md: 4, lg: 4 }}>
                  <Title order={4}>IP</Title>
                  <GridCol>N/A</GridCol>
                </GridCol>

                <GridCol  span={{ base: 6, md: 4, lg: 4 }}>
                  <Title order={4}>IP Local</Title>
                  <GridCol> {message?.status.lastLocalIp ? message?.status.lastLocalIp : 'N/A'}</GridCol>
                </GridCol>

                <GridCol  span={{ base: 6, md: 4, lg: 4 }}>
                  <Title order={4}>Software Version</Title>
                  <GridCol> {message?.status.softwareVersion ? message?.status.softwareVersion : 'N/A'}</GridCol>
                </GridCol>

                {statusList
                  ? statusList.map((status, index) => (
                      <GridCol key={index}  span={{ base: 6, md: 4, lg: 4 }}>
                        <Title order={4}>{lang[status.idLangKey]}</Title>
                        <GridCol>{lang[status.statusLangKey]}</GridCol>
                      </GridCol>
                    ))
                  : undefined}
              </Grid>
            </Group>

            <Group className='equipDetailsButtons'>
              <Button radius="md">
                {lang.EQUIPMENT_STATE_BUTTON_RESTART}
              </Button>
              <Button radius="md">
                {lang.EQUIPMENT_STATE_BUTTON_SHUTDOWN}
              </Button>
              <Button radius="md">
                {lang.EQUIPMENT_STATE_BUTTON_OPEN_ESCROW}
              </Button>
              <Button radius="md">
                {lang.EQUIPMENT_STATE_BUTTON_CLOSE_ESCROW}
              </Button>
              <Button radius="md">
                {lang.EQUIPMENT_STATE_BUTTON_OUT_OF_SERVICE}
              </Button>
              <Button radius="md">
                {lang.EQUIPMENT_STATE_BUTTON_IN_SERVICE}
              </Button>
              <Button radius="md">
                VPN Connect
              </Button>
              <Button radius="md">
                VPN Disconnect
              </Button>
            </Group>
          </>
        }
      />
    </>
  );
};

export default ShowEquipment;
