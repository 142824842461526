import { IShop } from '../../../libraries/shop';
import { AppAction } from '../actions';
import { ActionTypes } from './actionTypes';

export interface ShopState {
  shops: IShop[];
}

const initialState: ShopState = { shops: [] };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: AppAction<any>): ShopState => {
  switch (action.type) {
    case ActionTypes.SAVE_SHOPS: {
      return {
        ...state,
        shops: action.payload
      };
    }
    default:
      return state;
  }
};
