import { Group, Grid, GridCol } from '@mantine/core';
import './styles.scss';

export interface HeaderProps {
  pageTitle?: string;
}

const Header = ({ pageTitle }: HeaderProps) => {
  return (
    <Group className="header">
      <Grid>
        <GridCol span={12}>
          <h1>{pageTitle}</h1>
        </GridCol>
      </Grid>
    </Group>
  );
};

export default Header;
