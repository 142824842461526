import { combineReducers } from 'redux';
import login from './login/reducer';
import lang from './languages/reducer';
import sidebar from './sidebar/reducer';
import entities from './entities/reducer';
import shops from './shops/reducer';
import regions from './regions/reducer';
import currencies from './currencies/reducer';
import equipmentOptions from './equipment-options/reducer';

const appState = combineReducers({
  login,
  lang,
  sidebar,
  entities,
  shops,
  regions,
  currencies,
  equipmentOptions
});

export default appState;

export type AppState = ReturnType<typeof appState>;
