import { Container } from '@mantine/core';
import { IUser, PermissionOption } from '../../../libraries/users/index';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../../components/modal';
import UpdateContent from './update-content';
import './styles.scss';

interface ModalProps {
  user: IUser;
  openDetails: boolean;
  closed: () => void;
  deleteBtn: () => void;
  updated: () => void;
  lang: Language;
  permissionOptions: PermissionOption[];
}

const ShowUser = ({ user, openDetails, closed, updated, lang, permissionOptions }: ModalProps) => {
  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={closed}
        animation={true}
        title={user?.name}
        content={
          <>
            <Container>
              {user && (
                <UpdateContent
                  lang={lang}
                  user={user}
                  closed={closed}
                  permissionOptions={permissionOptions}
                  updated={updated}
                />
              )}
            </Container>
          </>
        }
        size={'xl'}
      />
    </>
  );
};

export default ShowUser;
