import React, { useEffect, useState } from 'react';
import { Loader, Table, Container, Group } from '@mantine/core';
import Header from '../../components/util/header';
import { useSelector } from 'react-redux';
import { fetchEquipmentList, fetchExistencesReport, IEquipmentListResponse } from '../../../libraries/equipment';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import moment from 'moment';
import ShowEquipment from '../../components/show-equipment';
import PaginationComponent from '../../components/pagination';
import { IShop } from '../../../libraries/shop';
import { LoginState } from '../../client-redux/login/reducer';
import { formatAmount2, handleShortTransactionType } from '../../utils/script';
import FilterBar from '../../components/filterbar/index';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

const Equipment = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const shops = useSelector<AppState, IShop[]>(state => state.shops.shops);
  const controller = new AbortController();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [entid, setEntid] = useState<number>(loginInfo.user?.entid);
  const [sid, setSid] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  /* const itemsPerPage = window.innerHeight < 600 ? 5 : Math.floor(window.innerHeight / 55 - 2); */
  const [limit, setLimit] = useState<number>(10);
  const [, setCurrentPage] = useState<number>(0);
  const [message, setMessage] = useState<IEquipmentListResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      getEquipmentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const onSearch = () => {
    setSearchTrigger(true);
  };

  useEffect(() => {
    getEquipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEquipmentList = () => {
    if (!loginInfo.token) {
      return;
    }
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);
    fetchEquipmentList(entid, sid, offset, limit, totalRows, controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
        }
      })
      .finally(() => setLoading(false));
  };

  const checkStatusForCell = (statusCode: number) => {
    if (statusCode === 4) {
      return <div className="offlineStatus">Offline</div>;
    } else {
      return <div className="onlineStatus">Online</div>;
    }
  };

  const checkStatusForRow = (statusCode: number) => {
    if (statusCode === 4) {
      return false;
    } else {
      return true;
    }
  };

  const checkEquipmentState = (equipmentState: number) => {
    if (!equipmentState) return;
    if (equipmentState === 0) {
      return <div>{lang.EQUIPMENT_STATE_BUTTON_IN_SERVICE}</div>;
    } else {
      return <div>{lang.EQUIPMENT_STATE_BUTTON_OUT_OF_SERVICE}</div>;
    }
  };

  const delegationCheck = (n: number) => {
    if (!shops) return '';
    if (n === 0) return '';

    return shops.find(shop => shop.sid === n)?.name;
  };

  const showEquipment = (eid: number) => {
    setActiveRow(eid);
    setOpenDetails(true);
  };

  const getExistencesReport = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    eid: number,
    period: number,
    controller: AbortController
  ) => {
    event.stopPropagation();
    fetchExistencesReport(eid, period, controller);
  };

  const handleEquipmentPeriod = (period: number) => {
    if (!period || 0) return;

    return period;
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateSid = (newSid: number, callback?: () => void) => {
    setSid(newSid);
    if (callback) {
      callback();
    }
  };

  const updateTableSid = (newSid: number, callback?: () => void) => {
    updateSid(newSid);
    if (callback) {
      callback();
    }
  };

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  useEffect(() => {
    getEquipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, sid]);

  return (
    <Container fluid classNames={{ root: 'equipment' }} >
      {openDetails && activeRow && lang && (
        <ShowEquipment eid={activeRow} openDetails={openDetails} hideBtn={() => setOpenDetails(false)} lang={lang} />
      )}
      <Header pageTitle={lang?.GLOBAL_EQUIPMENT} />

      <FilterBar
        onSearch={onSearch}
        listEntid={updateTableEntid}
        listLoaded={getEquipmentList}
        listSid={updateTableSid}
      />

      <Table.ScrollContainer minWidth={500}>
        <Table striped highlightOnHover verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th id="equipmentIdColumn">{lang?.EQUIPMENT_ID}</Table.Th>
              <Table.Th id="equipmentTypeColumn">{lang?.GLOBAL_TYPE}</Table.Th>
              <Table.Th>SN</Table.Th>
              <Table.Th id="equipmentDescriptionColumn">{lang?.EQUIPMENT_DESCRIPTION}</Table.Th>
              <Table.Th id="equipmentDelegationColumn">{lang?.USER_DELEGATION}</Table.Th>
              <Table.Th id="equipmentStatusColumn">{lang?.EQUIPMENT_COM_STATE}</Table.Th>
              <Table.Th id="equipmentStateColumn">{lang?.EQUIPMENT_STATE_MIN}</Table.Th>
              <Table.Th id="equipmentComDTColumn">{lang?.EQUIPMENT_COM_DATE_MIN}</Table.Th>
              <Table.Th id="equipmentComDTColumn">{lang?.EQUIPMENT_LAST_OPER_MIN}</Table.Th>
              <Table.Th id="equipmentComDTColumn">{lang?.EQUIPMENT_LAST_OPER_DT_MIN}</Table.Th>
              <Table.Th id="equipmentSoftwareVersionColumn">{lang?.EQUIPMENT_SOFTWARE_VERSION_MIN}</Table.Th>
              <Table.Th id="equipmentPeriodColumn">{lang?.GLOBAL_PERIOD}</Table.Th>
              <Table.Th id="equipmentAmountColumn">{lang?.GLOBAL_AMOUNT}</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {loading && (!message || searchTrigger) ? (
              <Table.Tr>
                <Table.Td colSpan={16}>
                  <Loader color="#016273" />
                </Table.Td>
              </Table.Tr>
            ) : (
              <>
                {noResults ? (
                  <Table.Tr>
                    <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                      {lang?.GLOBAL_NO_RESULTS}
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  message?.equipments?.map((equipmentDetails, index) => (
                    <Table.Tr
                      key={index}
                      onClick={
                        checkStatusForRow(equipmentDetails.equipment.status)
                          ? () => showEquipment(equipmentDetails.equipment.eid)
                          : undefined
                      }
                      style={
                        checkStatusForRow(equipmentDetails.equipment.status)
                          ? { cursor: 'pointer' }
                          : { cursor: 'not-allowed' }
                      }
                      className={checkStatusForRow(equipmentDetails.equipment.status) ? 'online-row' : 'offline-row'}
                    >
                      <Table.Td>{equipmentDetails.equipment.eid}</Table.Td>
                      <Table.Td>{equipmentDetails.equipment.etid}</Table.Td>
                      <Table.Td>{equipmentDetails.equipment.serialnumber}</Table.Td>
                      <Table.Td>{equipmentDetails.equipment.description}</Table.Td>
                      <Table.Td>{delegationCheck(equipmentDetails.equipment.sid)}</Table.Td>
                      <Table.Td>{checkStatusForCell(equipmentDetails.equipment.status)}</Table.Td>
                      <Table.Td>{checkEquipmentState(equipmentDetails.equipmentState?.equipmentState)}</Table.Td>
                      <Table.Td>{moment(equipmentDetails.lastComDt).format('DD/MM/YYYY HH:mm:ss')}</Table.Td>
                      <Table.Td>{handleShortTransactionType(lang, equipmentDetails.lastOperation)}</Table.Td>
                      <Table.Td>{moment(equipmentDetails.lastOperationDt).format('DD/MM/YYYY HH:mm:ss')}</Table.Td>
                      <Table.Td>{equipmentDetails.softwareVersion}</Table.Td>
                      <Table.Td>{handleEquipmentPeriod(equipmentDetails.period)}</Table.Td>
                      <Table.Td>{formatAmount2(equipmentDetails?.periodAmounts?.[0]?.amount ?? undefined)}</Table.Td>

                      <Table.Td>
                        {equipmentDetails.period ? (
                          <div
                            onClick={event =>
                              getExistencesReport(
                                event,
                                equipmentDetails.equipment.eid,
                                equipmentDetails.period,
                                controller
                              )
                            }
                          >
                            <Icon.IconFileTypePdf stroke={2} style={{ cursor: 'pointer' }} />
                          </div>
                        ) : undefined}
                      </Table.Td>
                    </Table.Tr>
                  ))
                )}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>

      <Group>
        <PaginationComponent
          total={message?.equipments ? Math.ceil(message.equipments.length / limit) : 0}
          statusOperCode={statusOperCode}
          itemsPerPage={limit}
          onPageChange={changePage}
          onResultsChange={resultsSelect}
        />
      </Group>
    </Container>
  );
};

export default Equipment;
