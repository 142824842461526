import React, { useState } from 'react';
import { Container, Button, useMantineColorScheme } from '@mantine/core';
import ModalComponent from '../modal';
import { fetchReceiptData } from '../../../libraries/apm';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

interface ShowReceiptProps {
  a001: number;
  movType: string;
  a031: number;
  a032: number;
  a029: number;
  dateTime: string;
}

interface PrintComponentProps {
  receiptInfo: string;
}

const PrintComponent: React.FC<PrintComponentProps> = ({ receiptInfo }) => {
  const printDivContent = () => {
    const newWin = window.open('', 'Print-Window');

    if (newWin) {
      newWin.document.open();
      newWin.document.write(
        '<html><body onload="window.print()" style="font-family:courier;font-size: 12px;">' +
          receiptInfo +
          '</body></html>'
      );
      newWin.document.close();
      newWin.close();
    } else {
      console.error('Failed to open print window');
    }
  };

  return <Icon.IconPrinter stroke={2} onClick={printDivContent} />;
};

const APMReceipt: React.FC<ShowReceiptProps> = ({ a001, movType, a031, a032, a029, dateTime }) => {
  const controller = new AbortController();
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [receiptInfo, setReceiptInfo] = useState<string>('');
  const { colorScheme } = useMantineColorScheme();

  const openModal = async () => {
    if (showModal) {
      return;
    }
    setShowModal(true);

    try {
      const response = await fetchReceiptData(a001, `${movType}`, a031, a032, a029, dateTime, controller);

      if (response?.receipt) {
        const decodedReceipt = decodeURIComponent(escape(atob(response.receipt)));
        const formattedReceipt = decodedReceipt.replace(/ /g, '&nbsp;').replace(/\n/g, '<br />');

        setReceiptInfo(formattedReceipt);
      }
    } catch (error) {
      console.error('Error fetching receipt data:', error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    controller.abort();
  };

  return (
    <>
      <Icon.IconReceipt stroke={2} onClick={openModal} />
      <ModalComponent
        show={showModal}
        handleClick={closeModal}
        animation={true}
        title={lang.GLOBAL_RECEIPT}
        size={'lg'}
        content={
          <>
            <Container bg={colorScheme === 'dark' ? '#1b1b1b': 'white'} classNames={{ root: 'APMReceipt' }}>
              <div style={{ padding: '10px', textAlign: 'left' }} className="apmReceiptDetails">
                <p dangerouslySetInnerHTML={{ __html: receiptInfo }} />
              </div>

              <div style={{ textAlign: 'center', padding: '10px' }}>
                <Button
                  type="button"
                  data-toggle="tooltip"
                  data-placement="right"
                  ng-click="printReceipt()"
                  color="#016273"
                  radius="md"
                >
                  <PrintComponent receiptInfo={receiptInfo} />
                </Button>
              </div>
            </Container>
          </>
        }
      />
    </>
  );
};

export default APMReceipt;
