import { AppAction } from '../actions';
import { ActionTypes } from './actionTypes';

export interface RegionState {
  regions: string[];
}

const initialState: RegionState = { regions: [] };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: AppAction<any>): RegionState => {
  switch (action.type) {
    case ActionTypes.SAVE: {
      return {
        ...state,
        regions: action.payload
      };
    }
    default:
      return state;
  }
};
