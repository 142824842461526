import { IEquipmentOption } from '../../../libraries/equipment';
import { AppAction } from '../actions';
import { ActionTypes } from './actionTypes';

export interface EquipmentOptionsState {
  options: IEquipmentOption[];
}

const initialState: EquipmentOptionsState = {
  options: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: AppAction<any>): EquipmentOptionsState => {
  switch (action.type) {
    case ActionTypes.SAVE_EQUIPMENT_OPTIONS: {
      return {
        ...state,
        options: action.payload
        };
      }
    default:
      return state;
  }
};
