import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Grid, GridCol, Group, Modal, TextInput, useMantineColorScheme } from '@mantine/core';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { updateApmEquipmentDetails } from '../../../libraries/apm';
import ModalComponent from '../modal';
import CustomModal from '../custom-modal';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

interface EditEquipments {
  posid: number;
  timeopenclose?: number;
  description: string;
  update: () => void;
}

const EditEquipmentPos: React.FC<EditEquipments> = ({ posid, timeopenclose, description: propDescription, update }) => {
  const controller = new AbortController();
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const [minutes, setMinutes] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [description, setDescription] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { colorScheme } = useMantineColorScheme();

  useEffect(() => {
    setDescription(propDescription || '');
    if (timeopenclose) {
      const hoursValue = Math.floor(timeopenclose / 60);
      const minutesValue = timeopenclose % 60;
      setHours(hoursValue < 10 ? hoursValue : hoursValue);
      setMinutes(minutesValue < 10 ? minutesValue : minutesValue);
    } else {
      setHours(0);
      setMinutes(0);
    }
  }, [propDescription, timeopenclose]);

  const openModal = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    controller.abort();
  };

  const convertToMinutes = () => {
    const paddedHours = hours.toString().padStart(2, '00');
    const paddedMinutes = minutes.toString().padStart(2, '00');
    return parseInt(paddedHours, 10) * 60 + parseInt(paddedMinutes, 10);
  };

  const handleSave = async () => {
    try {
      const totalMinutes = convertToMinutes();
      await updateApmEquipmentDetails(posid, totalMinutes, description, controller);
      setSuccessMessage(lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_SUCCESS || '');
      setShowSuccessDialog(true);
    } catch (error) {
      setErrorMessage(lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR || '');
      setShowErrorDialog(true);
    }
    update();
    setTimeout(() => {
      if (showModal) {
        closeModal();
      }
    }, 1000);
  };

  const header = (
    <div>
      <Modal.Title>{lang?.EQUIPMENT_EDIT}</Modal.Title>
      <div className="header-details">
        <span>{`${posid} / `}</span>
        <span>{`${description}`}</span>
      </div>
    </div>
  );

  return (
    <>
      <Icon.IconEdit stroke={2} onClick={openModal} />
      <ModalComponent
        show={showModal}
        handleClick={closeModal}
        title={header}
        animation={true}
        size={'lg'}
        content={
          <Container bg={colorScheme === 'dark' ? '#1b1b1b': 'white'} classNames={{ root: 'editEquipment' }}>
            <Grid>
              <GridCol>
                <h3>{lang?.EQUIPMENT_AUTOMATIC_CLOSE}</h3>
              </GridCol>
            </Grid>

            <Group classNames={{ root: 'hoursAndMinutes' }}>
              <TextInput
                label={lang?.GLOBAL_HOURS}
                value={hours}
                type="number"
                onChange={e => setHours(parseInt(e.target.value))}
              />
              <TextInput
                label={lang?.GLOBAL_MINUTES}
                value={minutes}
                type="number"
                onChange={e => setMinutes(parseInt(e.target.value))}
              />
            </Group>

            <Group classNames={{ root: 'description' }}>
              <TextInput
                label={lang?.EQUIPMENT_DESCRIPTION}
                value={description}
                type="text"
                id="description"
                name="description"
                onChange={e => setDescription(e.target.value)}
              />
            </Group>

            <Group>
              <Button color="green" radius="md" onClick={handleSave}>
                {lang?.GLOBAL_SAVE_BUTTON}
              </Button>
            </Group>
          </Container>
        }
      />

      <CustomModal
        opened={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        message={successMessage}
        backgroundColor="#3BDF70"
      />

      <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
      />
    </>
  );
};

export default EditEquipmentPos;
