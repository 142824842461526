/* import moment from 'moment';
 */import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';
import { handleDownload } from '../../app/utils/script';

const TRANS_BASE_URL = `${zps_ea_war}/tb401/transaction/`;
const TRANS_LOG_URL = `${zps_ea_war}/log/`;

const APM_TRANS_BASE_URL = `${zps_ea_war}/apm/transaction/`;
const APM_PERIODS_BASE_URL = `${zps_ea_war}/apm/period/`;


export type ITransaction = {
  bankid: number;
  billsInDoubt: number;
  cashIn?: ICash[];
  cashOut?: ICash[];
  cid: number;
  code: number;
  coinsInDoubt: number;
  dateTime: Date;
  docCode: string;
  docType: number;
  eid: number;
  entid: number;
  envTotals: number;
  etvid: number;
  gid: number;
  mc: IMC[];
  msid: number;
  nseq: number;
  period: number;
  shift: number;
  sid: number;
  status: string;
  type: string;
  value: number;
  valueDis: number;
  valueRec: number;
  valueType: string;
  uid: number;
};

export type ICash = {
  destiny: number;
  index: number;
  quantity: number;
};

export type IMC = {
  destiny: number;
  index: number;
  origin: number;
  quantity: number;
};



export interface ITransactionResponse extends ApiResponse {
  payment: boolean;
  totalRows: number;
  transactions: ITransaction[];
  type: string;
}



export type IFile = {
  fileBytes: string;
  fileName: string;
};

export interface IFileResult extends ApiResponse {
  fileBytes: string;
  fileName: string;
  type: string;
}

export interface IReportResult extends ApiResponse {
  report: string;
  format: string;
  name: string;
  mime: string;
  type: string;
}

export const fetchTransactionList = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  eid: number,
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController
) => {
  const { data } = await axios.get(
    `${TRANS_BASE_URL}?begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&eid=${eid}&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );

  return data;
};

export const fetchLog = async (eid: number, period: number, code: number, controller: AbortController) => {
  try {
    const response = await axios.get(`${TRANS_LOG_URL}?eid=${eid}&period=${period}&code=${code}`, {
      signal: controller.signal
    });

    handleDownload(response.data.fileBytes, response.data.fileName, response.data.fileType);
  } catch (error) {
    console.error('Failed to fetch Log file:', error);
  }
};

export const fetchTransactionXLS = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  eid: number,
  controller: AbortController
) => {
  try {
    const response = await axios.get(
      `${TRANS_BASE_URL}report/list/?format=xls&begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&eid=${eid}`,
      {
        signal: controller.signal
      }
    );
    handleDownload(response.data.report, response.data.name, response.data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};

export const fetchAPMTransactionsXLS = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  offset: number,
  limit: number,
  controller: AbortController
) => {
  try {
    const response = await axios.get(
      `${APM_TRANS_BASE_URL}report/?format=xls&begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&offset=${offset}&limit=${limit}`,
      {
        signal: controller.signal
      }
    );
    handleDownload(response.data.report, response.data.name, response.data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};

export const fetchAPMPeriodsXLS = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  offset: number,
  limit: number,
  controller: AbortController
) => {
  try {
    const response = await axios.get(
      `${APM_PERIODS_BASE_URL}report/?format=xls&begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&offset=${offset}&limit=${limit}`,
      {
        signal: controller.signal
      }
    );
    handleDownload(response.data.report, response.data.name, response.data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};

export const fetchAPMPeriodsPDF = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  offset: number,
  limit: number,
  controller: AbortController
) => {
  try {
    const response = await axios.get(
      `${APM_PERIODS_BASE_URL}report/?format=pdf&begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&offset=${offset}&limit=${limit}`,
      {
        signal: controller.signal
      }
    );
    handleDownload(response.data.report, response.data.name, response.data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};






