import { PermissionOption, Permission, IUser } from '../../../libraries/users';

//Prepare user.permissions before user update
//"permissions" is a representation of UI permissions list
//"user" is the selected user
export const permissionsToUser = (permissions: PermissionOption[], user: IUser) => {
  user.permissions = [];
  permissions.forEach(function (option) {
    if (option.selected) {
      let permission : Permission | undefined =  user.permissions?.find( (elem: Permission) => option.appId === elem.appId);
      if(!permission){
        user.permissions?.push({
          appId: option.appId,
          permissionCode: option.mask
        });
      }else{
        permission.permissionCode |= option.mask;
      }
    }
  });
};

//Prepare permissions options with user.permissions
//"permissions" is a representation of UI permissions list
//"user" is the selected user
export const preparePermissions = (permissions: PermissionOption[], user: any) => {
  if (!user.permissions) {
    return;
  }
  permissions.forEach(function (option) {
    option.selected = false;
    user.permissions.forEach(function (userPermission: Permission) {
      if (option.appId === userPermission.appId) {
        option.selected = (userPermission.permissionCode & option.mask) !== 0;
      }
    });
  });
};

