import { TextInput, Group, PasswordInput } from '@mantine/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';

interface UserIDLoginFormProps {
  entid: string | undefined;
  uid: string | undefined;
  password: string;
  onEntidChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUidChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const UserIDLoginForm: React.FC<UserIDLoginFormProps> = ({
  entid,
  uid,
  password,
  onEntidChange,
  onUidChange,
  onPasswordChange,
}) => {
  const lang = useSelector<AppState, Language | undefined>(state => state.lang.lang);

  return (
    <>
      <Group>
        <TextInput
          label={lang?.GLOBAL_ENTITY}
          placeholder=""
          value={entid || ''}
          onChange={onEntidChange}
        />
      </Group>
      <Group>
        <TextInput
          label={lang?.LOGIN_USER}
          placeholder=""
          value={uid || ''}
          onChange={onUidChange}
        />
      </Group>
      <Group>
        <PasswordInput
          label={lang?.GLOBAL_PASSWORD}
          placeholder=""
          value={password}
          onChange={onPasswordChange}
        />
      </Group>

    </>
  );
};

export default UserIDLoginForm;
