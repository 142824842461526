import { ICurrency } from '../../../libraries/currencies';
import { AppAction } from '../actions';
import { ActionTypes } from './actionTypes';

export interface CurrencyState {
  currencies: ICurrency[];
}

const initialState: CurrencyState = { currencies: [] };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: AppAction<any>): CurrencyState => {
  switch (action.type) {
    case ActionTypes.SAVE_CURRENCIES: {
      return {
        ...state,
        currencies: action.payload
      };
    }
    default:
      return state;
  }
};
