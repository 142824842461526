import moment from 'moment';
import { Container, Group, GridCol, Grid, Button, useMantineColorScheme } from '@mantine/core';
import { IBankTransaction } from '../../../libraries/bank-transactions';
import { Language } from '../../languages/languageHandler';
import { formatAmount2, formatTransactionStatus, handleTransactionType } from '../../utils/script';
import ModalComponent from '../../components/modal';
import CurrencyTable from '../../components/util/currency-table';
import { ICurrency } from '../../../libraries/currencies';
import './styles.scss';

interface ShowTransactionProps {
  transaction: IBankTransaction;
  openDetails: boolean;
  hideBtn: () => void;
  lang: Language;
  currency: ICurrency;
  getLog: () => void;
}

const ShowBankTransaction = ({ transaction, openDetails, hideBtn, lang, currency, getLog }: ShowTransactionProps) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <>
      <ModalComponent
        show={openDetails}
        handleClick={hideBtn}
        animation={true}
        title={lang.TRANSACTION_DETAILS}
        size={'xl'}
        content={
          <>
            <Container bg={colorScheme === 'dark' ? '#1b1b1b': 'white'} classNames={{ root: 'transactionDetails' }}>
              <Group>
                <Grid justify="flex-start" align="flex-start" gutter="sm">
                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.USER_DELEGATION}</strong>
                    <GridCol>{transaction.entid}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.EQUIPMENT_ID}</strong>
                    <GridCol>{transaction.eid}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_PERIOD}</strong>
                    <GridCol>{transaction.period}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLASS_CODE}</strong>
                    <GridCol>{transaction.code}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.TRANSACTION_DEPOSITOR}</strong>
                    <GridCol>{transaction.depositor}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_ACCOUNT}</strong>
                    <GridCol>{transaction.account}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_DATE_TIME}</strong>
                    <GridCol>{moment(transaction.dateTime).format('DD-MM-YYYY HH:mm:ss')}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_AMOUNT}</strong>
                    <GridCol>{formatAmount2(transaction.value)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.TRANSACTION_RECEIVED}</strong>
                    <GridCol>{formatAmount2(transaction.valueRec)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.TRANSACTION_DISPENSED}</strong>
                    <GridCol>{formatAmount2(transaction.valueDis)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.GLOBAL_REASON}</strong>
                    <GridCol>{formatAmount2(transaction.reason)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.EQUIPMENT_COM_STATE}</strong>
                    <GridCol>{handleTransactionType(lang, transaction.type)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.TRANSACTION_PAYMENT_METHOD}</strong>
                    <GridCol>{formatTransactionStatus(lang, transaction.status)}</GridCol>
                  </GridCol>

                  <GridCol span={{ base: 12, md: 6, lg: 3 }}>
                    <strong>{lang.TRANSACTION_EXTERNAL_ID}</strong>
                    <GridCol>{formatTransactionStatus(lang, transaction.hostTransactionID)}</GridCol>
                  </GridCol>
                </Grid>

                {transaction.cashIn && currency && (
                  <>
                    <strong>{lang.CURRENCY_TABLE_CASH_IN}</strong>
                    <CurrencyTable lang={lang} currency={currency} cash={transaction.cashIn} />
                  </>
                )}

                <Button color="#016273" radius="md" onClick={() => getLog()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z" />
                  </svg>
                  Logs
                </Button>
              </Group>
            </Container>
          </>
        }
      />
    </>
  );
};

export default ShowBankTransaction;
