import { useState } from 'react';
import { Table } from '@mantine/core';
import { ICash } from '../../../libraries/transactions';
import { prepareQuantities } from '../../utils/script';
import { ICurrency } from '../../../libraries/currencies';
import { Language } from '../../languages/languageHandler';
import './styles.scss';

interface CurrencyTableProps {
  lang: Language;
  currency: ICurrency;
  cash: ICash[];
}

const CurrencyTable = ({ lang, currency, cash }: CurrencyTableProps) => {
  const [nrCoins] = useState<number>(currency.nCoins);
  const [nrBills] = useState<number>(currency.nBills);

  const denominations = prepareQuantities(cash, currency);

  const calcCoinsTotalPercentage = (nrCoins: number, nrBills: number) => {
    let total = nrCoins + nrBills;
    return (93 * nrCoins) / total;
  };

  const calcBillsTotalPercentage = (nrCoins: number, nrBills: number) => {
    let total = nrCoins + nrBills;

    return (93 * nrBills) / total;
  };

  const mapTableHeader = (headerCurrency: ICurrency) => {
    if (!headerCurrency) return;
    if (!headerCurrency.denominations) return;
    return headerCurrency.denominations.map((denomination, index, denominations) => (
      <Table.Th
        style={{
          width: `${
            denomination.type === 1
              ? calcCoinsTotalPercentage(nrCoins, nrBills) / nrCoins
              : calcBillsTotalPercentage(nrCoins, nrBills) / nrBills
          }%`
        }}
        className={`currencyValueCell${index === 0 ? ' first-cell' : ''}${
          index === denominations.length - 1 ? ' last-cell' : ''
        }`}
      >
        {denomination.value * currency.factor}
      </Table.Th>
    ));
  };
  return (
    <Table.ScrollContainer minWidth={500}>
    <Table className="currencyTable" withTableBorder withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          <Table.Th rowSpan={2}>{currency.currencyStr}</Table.Th>
          {nrCoins !== 0 ? <Table.Th colSpan={nrCoins}>{lang?.GLOBAL_COINS}</Table.Th> : undefined}
          {nrBills !== 0 ? <Table.Th colSpan={nrBills}>{lang?.GLOBAL_BILLS}</Table.Th> : undefined}
        </Table.Tr>
        <Table.Tr>{mapTableHeader(currency)}</Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{lang.GLOBAL_AMOUNT}</Table.Td>
          {denominations?.map(denomination => <Table.Td>{denomination.amount}</Table.Td>)}
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{lang.GLOBAL_QUANTITY}</Table.Td>
          {denominations?.map(denomination => <Table.Td>{denomination.quantity}</Table.Td>)}
        </Table.Tr>
      </Table.Tbody>
    </Table>
    </Table.ScrollContainer>

  );
};

export default CurrencyTable;