import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { IAPMPeriodsResponse, fetchComissionsData } from '../../../libraries/apm';
import ModalComponent from '../modal';
import { Loader, Table, Modal } from '@mantine/core';
import * as Icon from '@tabler/icons-react';

import './styles.scss';

interface ShowComissionsProps {
  a001: number;
  movType: string;
  a031: number;
  a032: number;
  a029: number;
  dateTime: string;
}

const ShowComissionsDetails = ({ a001, movType, a031, a032, a029, dateTime }: ShowComissionsProps) => {
  const controller = new AbortController();
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(true);
  const [message, setMessage] = useState<IAPMPeriodsResponse>();
  const [searchTrigger] = useState<boolean>(false);

  useEffect(() => {
    if (showModal) {
      setLoading(true);
      fetchComissionsData(a001, `${movType}`, a031, a032, a029, dateTime, controller)
        .then(response => {
          setLoading(false);
          setNoResults(false);
          setMessage(response);
        })
        .catch(error => {
          setLoading(false);
          console.error('Error fetching receipt data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const openModal = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    controller.abort();
  };

  const header = (
    <div className="header">
      <Modal.Title>{lang?.GLOBAL_COMISSIONS}</Modal.Title>
      <div className="header-details">
        {message?.commissions && message.commissions.length > 0 ? (
          <div>
            <span>{`${message.commissions[0]?.key.a001 ?? ''} / `}</span>
            <span>{`${message.commissions[0]?.key.a031 ?? ''}`}</span>
          </div>
        ) : (
          message?.periods &&
          message.periods.length > 0 && (
            <div>
              <span>{`${message.periods[0]?.key.a001 ?? ''} / `}</span>
              <span>{`${message.periods[0]?.key.a031 ?? ''}`}</span>
            </div>
          )
        )}
      </div>
    </div>
  );

  return (
    <>
      <Icon.IconListDetails stroke={2} onClick={openModal} />
      <ModalComponent
        show={showModal}
        handleClick={closeModal}
        title={header}
        size={'auto'}
        content={
          <Modal.Body>
            <Table.ScrollContainer minWidth={500}>
              <Table className={'comissions-table'} withTableBorder withColumnBorders>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Td>POS</Table.Td>
                    <Table.Td>{lang?.GLOBAL_PERIOD}</Table.Td>
                    <Table.Td>{lang?.GLOBAL_CONTRACT}</Table.Td>
                    <Table.Td>{lang?.TRANSACTION_NUMBERS}</Table.Td>
                    <Table.Td>{lang?.TRANSACTION_AMOUNT_CHARGED}</Table.Td>
                    <Table.Td>{lang?.TRANSACTION_COMISSION_AMOUNT}</Table.Td>
                    <Table.Td>{lang?.GLOBAL_COMISSIONS}</Table.Td>
                  </Table.Tr>
                </Table.Thead>

                <Table.Tbody style={{ textAlign: 'center' }}>
                  {loading || (!message && searchTrigger) ? (
                    <Table.Tr>
                      <Table.Td colSpan={16}>
                        <Loader color="#016273" />
                      </Table.Td>
                    </Table.Tr>
                  ) : noResults || message?.commissions?.length === 0 ? (
                    <Table.Tr>
                      <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                        {lang?.GLOBAL_NO_RESULTS}
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    <>
                      {message?.commissions?.map((commission, index) => (
                        <Table.Tr key={index}>
                          <Table.Td>{commission.key.a001}</Table.Td>
                          <Table.Td>{commission.key.a031}</Table.Td>
                          <Table.Td>{commission.a054}</Table.Td>
                          <Table.Td>{commission.a052}</Table.Td>
                          <Table.Td>{(commission.amount / 100).toFixed(2) + ' €'}</Table.Td>
                          <Table.Td>{(commission.amountCommission / 100).toFixed(2) + ' €'}</Table.Td>
                          <Table.Td>{commission.pCommission}</Table.Td>
                        </Table.Tr>
                      ))}
                    </>
                  )}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
          </Modal.Body>
        }
      />
    </>
  );
};

export default ShowComissionsDetails;
