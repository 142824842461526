import { IEntity } from '../../../libraries/entity';
import { IUser } from '../../../libraries/users';
import { AppAction } from '../actions';
import { ActionTypes } from './actionTypes';

export interface User {
  entid: string;
  uid: string;
}

export interface LoginState {
  loading?: boolean;
  user: IUser;
  entity?: IEntity;
  loginSuccess: boolean;
  token?: string;
}

const initialState: LoginState = {
   user: {entid: 0, uid:0, lang:0},
   loginSuccess: false 
  };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: AppAction<any>): LoginState => {
  switch (action.type) {
    case ActionTypes.LOGGING_IN: {
      return {
        ...state,
        loginSuccess: false,
        loading: true
      };
    }
    case ActionTypes.LOGGED_IN: {
      return {
        ...state,
        ...action.payload,
        loginSuccess: true
      };
    }
    case ActionTypes.LOGGED_ERROR: {
      return { ...state, loginSuccess: false, loading: false };
    }
    case ActionTypes.LOGOUT: {
      return {
        ...initialState,
        loginSuccess: false
      };
    }
    default:
      return state;
  }
};
