import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { IAPMTransactionResponse, fetchTransactionData } from '../../../libraries/apm';
import { Loader, Table } from '@mantine/core';
import ModalComponent from '../modal';
import moment from 'moment';
import ShowReceipt from '../apm-receipt';
import * as Icon from '@tabler/icons-react';

import './styles.scss';

interface ShowTransactionsProps {
  a001: number;
  movType: string;
  a031: number;
  a032: number;
  a029: number;
  dateTime: string;
}

const ShowTransactionsDetails = ({ a001, movType, a031, a032, a029, dateTime }: ShowTransactionsProps) => {
  const controller = new AbortController();
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(true);
  const [message, setMessage] = useState<IAPMTransactionResponse>();
  const [searchTrigger] = useState<boolean>(false);

  useEffect(() => {
    if (showModal) {
      setLoading(true);
      fetchTransactionData(a001, `${movType}`, a031, a032, a029, dateTime, controller)
        .then(response => {
          setLoading(false);
          setNoResults(false);
          setMessage(response);
        })
        .catch(error => {
          setLoading(false);
          console.error('Error fetching receipt data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const openModal = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    controller.abort();
  };

  return (
    <>
      <Icon.IconCreditCard stroke={2} onClick={openModal} />
      <ModalComponent
        show={showModal}
        handleClick={closeModal}
        animation={true}
        title={lang.TRANSACTIONS}
        size={'auto'}
        content={
          <>
            <Table.ScrollContainer minWidth={500}>
              <Table className={'transactions-table'} withTableBorder withColumnBorders>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Td>POS</Table.Td>
                    <Table.Td>{lang?.GLOBAL_PERIOD}</Table.Td>
                    <Table.Td>#</Table.Td>
                    <Table.Td>{lang?.GLOBAL_DATE_TIME}</Table.Td>
                    <Table.Td>{lang?.GLOBAL_AMOUNT}</Table.Td>
                    <Table.Td></Table.Td>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {loading || (!message && searchTrigger) ? (
                    <Table.Tr>
                      <Table.Td colSpan={16}>
                        <Loader color="#016273" />
                      </Table.Td>
                    </Table.Tr>
                  ) : noResults || message?.transactions?.length === 0 ? (
                    <Table.Tr>
                      <Table.Td colSpan={16}>{lang?.GLOBAL_NO_RESULTS}</Table.Td>
                    </Table.Tr>
                  ) : (
                    <>
                      {message?.transactions?.map((transaction, index) => (
                        <Table.Tr key={index}>
                          <Table.Td>{transaction.key.a001}</Table.Td>
                          <Table.Td>{transaction.key.a031}</Table.Td>
                          <Table.Td>{transaction.key.a032}</Table.Td>
                          <Table.Td>{moment(transaction.dateTime).format('YYYY-MM-DD HH:mm:ss')}</Table.Td>
                          <Table.Td>{(transaction.amount / 100).toFixed(2) + ' €'}</Table.Td>

                          <Table.Td>
                            <ShowReceipt
                              a001={transaction.key.a001}
                              movType={transaction.key.movType}
                              a031={transaction.key.a031}
                              a032={transaction.key.a032}
                              a029={transaction.key.a029}
                              dateTime={transaction.key.dateTime}
                            />
                          </Table.Td>
                        </Table.Tr>
                      ))}
                    </>
                  )}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
          </>
        }
      />
    </>
  );
};

export default ShowTransactionsDetails;
