import { IEntity } from '../../../libraries/entity';
import { AppAction } from '../actions';
import { ActionTypes } from './actionTypes';

export interface EntitiesState {
  entities: IEntity[];
}

const initialState: EntitiesState = { entities: [] };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: AppAction<any>): EntitiesState => {
  switch (action.type) {
    case ActionTypes.SAVE_ENTITIES: {
      return {
        ...state,
        entities: action.payload
      };
    }
    default:
      return state;
  }
};
