import moment from 'moment';
import { useEffect, useState } from 'react';
import { Loader, Container, Group, Table } from '@mantine/core';
import Header from '../../../components/util/header';
import { useSelector } from 'react-redux';
import { fetchAPMPeriodsList, IAPMPeriodsResponse } from '../../../../libraries/apm';
import { AppState } from '../../../client-redux/reducers';
import { Language } from '../../../languages/languageHandler';
import PaginationComponent from '../../../components/pagination';
import { LoginState } from '../../../client-redux/login/reducer';
import APMReceipt from '../../../components/apm-receipt';
import FilterBar from '../../../components/filterbar/index';
import ShowComissionsDetails from '../../../components/show-comissions-details';
import ShowTransactionsDetails from '../../../components/show-transactions-details';
import { IShop } from '../../../../libraries/shop';
import { fetchAPMPeriodsPDF, fetchAPMPeriodsXLS } from '../../../../libraries/transactions';
import './styles.scss';

const APMPeriods = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const user = useSelector<AppState, LoginState>(state => state.login);
  const shops = useSelector<AppState, IShop[]>(state => state.shops.shops);
  const controller = new AbortController();

  const [loading, setLoading] = useState(false);
  const [beginDate, setBeginDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DDT00:00:00.000'));
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000'));
  const [entid, setEntid] = useState<number>(user.user?.entid);
  const [sid, setSid] = useState<number>(0);
  const [posid, setPosid] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  /* const itemsPerPage = window.innerHeight < 600 ? 5 : Math.floor(window.innerHeight / 55 - 2); */
  const [limit, setLimit] = useState<number>(10);
  const [, setCurrentPage] = useState<number>(0);
  const [message, setMessage] = useState<IAPMPeriodsResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();
  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      getAPMPeriodsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const onSearch = () => {
    setSearchTrigger(true);
  };

  const getAPMPeriodsList = () => {
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);
    fetchAPMPeriodsList(beginDate, endDate, entid, sid, posid, offset, limit, totalRows, controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setNoResults(false);
          setMessage(response);
          setStatusOperCode(response.statusOper.code);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          // console.log('API request aborted');
        } else {
          console.error('API request error:', error);
        }
      })
      .finally(() => setLoading(false));
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updatePosid = (newPosid: number, callback?: () => void) => {
    setPosid(newPosid);
    if (callback) {
      callback();
    }
  };

  const updateTablePosList = (newPosid: number, callback?: () => void) => {
    updatePosid(newPosid);
    if (callback) {
      callback();
    }
  };

  const updateSid = (newSid: number, callback?: () => void) => {
    setSid(newSid);
    if (callback) {
      callback();
    }
  };

  const updateTableSid = (newSid: number, callback?: () => void) => {
    updateSid(newSid);
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    getAPMPeriodsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, sid, posid]);

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  const getXLSReport = () => {
    return fetchAPMPeriodsXLS(beginDate, endDate, entid, sid, offset, limit, controller);
  };

  const getPDFReport = () => {
    return fetchAPMPeriodsPDF(beginDate, endDate, entid, sid, offset, limit, controller);
  };

  return (
    <Container fluid classNames={{ root: 'APMPeriods' }} >
      <Header pageTitle={lang?.GLOBAL_PERIOD} />

      <FilterBar
        onSearch={onSearch}
        listEntid={updateTableEntid}
        listLoaded={getAPMPeriodsList}
        listSid={updateTableSid}
        updateBeginDate={setBeginDate}
        updateEndDate={setEndDate}
        listPosId={updateTablePosList}
        XLSReport={getXLSReport}
        PDFReport={getPDFReport}
      />

      <Table.ScrollContainer minWidth={500}>
        <Table striped verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>POS</Table.Th>
              <Table.Th>{lang?.USER_DELEGATION}</Table.Th>
              <Table.Th>{lang?.GLOBAL_PERIOD}</Table.Th>
              <Table.Th>{lang?.GLOBAL_DATE_TIME}</Table.Th>
              <Table.Th>{lang?.TRANSACTION_NUMBER}</Table.Th>
              <Table.Th>{lang?.TRANSACTION_AMOUNT_CHARGED}</Table.Th>
              <Table.Th>{lang?.TRANSACTION_COMISSION_AMOUNT}</Table.Th>
              <Table.Th>{lang?.TRANSACTION_AMOUNT_CREDITED}</Table.Th>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {loading && (!message || searchTrigger) ? (
              <Table.Tr>
                <Table.Td colSpan={16}>
                  <Loader color="#016273" />
                </Table.Td>
              </Table.Tr>
            ) : (
              <>
                {noResults ? (
                  <Table.Tr>
                    <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                      {lang?.GLOBAL_NO_RESULTS}
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  message?.periods?.map((period, index) => {
                    const matchingShop = shops.find(shop => shop.sid === period.sid);
                    return (
                      <Table.Tr key={index}>
                        <Table.Td>{period.key.a001}</Table.Td>
                        <Table.Td>{matchingShop ? matchingShop.name : ''}</Table.Td>
                        <Table.Td>{period.key.a031}</Table.Td>
                        <Table.Td>{moment(period.dateTime).format('YYYY-MM-DD HH:mm:ss')}</Table.Td>
                        <Table.Td>{period.totalTransactions}</Table.Td>
                        <Table.Td>{(period.amount / 100).toFixed(2) + ' €'}</Table.Td>
                        <Table.Td>{(period.amountCommitions / 100).toFixed(2) + ' €'}</Table.Td>
                        <Table.Td>{(period.amountWithoutCommitions / 100).toFixed(2) + ' €'}</Table.Td>
                        <Table.Td>
                          <ShowComissionsDetails
                            a001={period.key.a001}
                            movType={period.key.movType}
                            a031={period.key.a031}
                            a032={period.key.a032}
                            a029={period.key.a029}
                            dateTime={period.key.dateTime}
                          />
                        </Table.Td>
                        <Table.Td>
                          <ShowTransactionsDetails
                            a001={period.key.a001}
                            movType={period.key.movType}
                            a031={period.key.a031}
                            a032={period.key.a032}
                            a029={period.key.a029}
                            dateTime={period.key.dateTime}
                          />
                        </Table.Td>
                        <Table.Td>
                          <APMReceipt
                            a001={period.key.a001}
                            movType={period.key.movType}
                            a031={period.key.a031}
                            a032={period.key.a032}
                            a029={period.key.a029}
                            dateTime={period.key.dateTime}
                          />
                        </Table.Td>
                      </Table.Tr>
                    );
                  })
                )}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>

      <Group>
        <PaginationComponent
          total={message?.totalRows}
          statusOperCode={statusOperCode} // Pass statusOperCode
          itemsPerPage={limit}
          onPageChange={changePage}
          onResultsChange={resultsSelect}
        />
      </Group>
    </Container>
  );
};

export default APMPeriods;
