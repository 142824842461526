import { AppState } from './reducers';

export const loadFromLocalStorage = () => {
  try {
    let state = JSON.parse(localStorage.getItem('state') || '');
    // console.log('loadFromLocalStorage: ', state);
    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveToLocalStorage = (state: AppState) => {
  // console.log('saveToLocalStorage: ', state);
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};
