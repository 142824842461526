import { ApiResponse, zps_ea_war } from '../zps';
import axios from 'axios';

const REGION_BASE_URL = `${zps_ea_war}/region`;

export interface IRegionResponse extends ApiResponse {
  regions: string[];
}

export const fetchRegions = async () => {
  const response = await axios.get(REGION_BASE_URL);
  return response.data;
};
