import { Select, Group, Button, TextInput } from '@mantine/core';
import * as Icon from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { IEntity } from '../../../libraries/entity';
import { AppState } from '../../client-redux/reducers';
import { useState } from 'react';
import { LoginState } from '../../client-redux/login/reducer';
import { Language } from '../../languages/languageHandler';
import { UseQueryResult, useQuery } from 'react-query';
import { PermissionOptionResponse, fetchPermissions } from '../../../libraries/users';
import NewUser from '../../pages/users/new-user';
import './styles.scss';

const UsersFilterBar = ({
  onSearch,
  listLoaded,
  listEntid,
  listName,
  listIDUser
}: {
  onSearch: () => void;
  listEntid: (newEntid: number, callback?: () => void) => void;
  listLoaded: () => void;
  listName: (newName: string, callback?: () => void) => void;
  listIDUser: (newUID: number, callback?: () => void) => void;
}) => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const user = useSelector<AppState, LoginState>(state => state.login);
  const entities = useSelector<AppState, IEntity[]>(state => state.entities.entities);
  const permissionOptionData: UseQueryResult<PermissionOptionResponse> = useQuery([], () => fetchPermissions());
  const [entid, setEntid] = useState<number>(user.user?.entid);
  const [, setName] = useState<string>('');
  const [, setUid] = useState<number>(0);
  const [openNew, setOpenNew] = useState<boolean>(false);

  const handleSearch = () => {
    listLoaded();
    onSearch();
  };

  /* Entidade handle*/
  const handleEntitySelect = (value: number | 0) => {
    if (value !== null) {
      const selectedEntid = Number(value);
      listEntid(selectedEntid, () => {});
      setEntid(selectedEntid);
    }
  };

  /* Name handle*/
  const handleNameSelect = (value: string | '') => {
    if (value !== null) {
      const selectedName = String(value);
      listName(selectedName, () => {});
      setName(selectedName);
    }
  };

  /* UID handle*/
  const handleUIDSelect = (value: number | 0) => {
    if (value !== null) {
      const selecteUID = Number(value);
      listIDUser(selecteUID, () => {});
      setUid(selecteUID);
    }
  };

  const toggleNewUserModal = () => {
    setOpenNew(!openNew);
  };

  return (
    <Group className="UsersFilterBar">
      {lang && permissionOptionData.data && (
        <NewUser
          permissionOptionData={permissionOptionData.data}
          lang={lang}
          isNew={openNew}
          hideBtn={() => toggleNewUserModal()}
        />
      )}

      {/* Entity */}
      <Select
        size="sm"
        withCheckIcon={true}
        label={lang?.GLOBAL_ENTITY}
        data={[
          { value: '0', label: lang?.GLOBAL_ALL },
          ...(entities ? entities.map(entity => ({ value: entity.entid.toString(), label: entity.name })) : [])
        ]}
        value={entid.toString()}
        onChange={value => handleEntitySelect(Number(value))}
      />

      {/* Name */}
      <TextInput
        size="sm"
        label={lang?.GLOBAL_NAME}
        placeholder=""
        onChange={event => handleNameSelect(event.target.value)}
      />

      {/* UID */}
      <TextInput
        size="sm"
        label={lang?.USER_UID}
        placeholder=""
        onChange={event => handleUIDSelect(Number(event.target.value))}
      />

      {/* Search */}
      <Button color="#016273" radius="md" className="searchButton" onClick={handleSearch}>
        {lang?.GLOBAL_SEARCH}
      </Button>

      {/* Add User */}
      <Button color="#016273" radius="md" className="AddUserhButton" onClick={toggleNewUserModal}>
        <Icon.IconUsersPlus />
      </Button>
    </Group>
  );
};

export default UsersFilterBar;
