import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';
import { handleDownload } from '../../app/utils/script';
import { IEntity } from '../entity';

const EQUIP_BASE_URL = `${zps_ea_war}/equipment`;
const HELPDESK_EQUIP_BASE_URL = 'https://service.zarph.com/zarph-zps-ms-ea-war/msapi/equipment';

export type IEquipment = {
  almostFullPerc: number;
  almostFullValue: number;
  bag: boolean;
  bankid: number;
  cardInsertionTimeout: number;
  cardRemovalTimeout: number;
  comuid: number;
  currencies: IEquipmentCurrency[];
  dataInsertionTimeout: number;
  declarationTimeout: number;
  depositTimeout: number;
  description: string;
  eid: number;
  entid: number;
  equiId: number;
  etid: number;
  etvid: number;
  fullPerc: number;
  fullValue: number;
  fullValueCid: number;
  gid: number;
  idTerminal: number;
  inuse: number;
  lang: number;
  languages: IEquipmentLanguage[];
  lid: number;
  maintid: number;
  maintid2: number;
  maxBills: number;
  maxCoins: number;
  p43sf8: number;
  region: string;
  sa1: number;
  sa2: number;
  sa3: number;
  sa4: number;
  sa5: number;
  sa6: number;
  sa7: number;
  sa8: number;
  secur: number;
  serialnumber: string;
  sid: number;
  status: number;
  suppid: number;
  token: string;
  zkey: string;
  amount: string;
};

export type IEquipmentState = {
  cardreaderState: number;
  depositToRecover: boolean;
  diskUsage: number;
  equipmentState: number;
  fakeNoteDetected: boolean;
  moosState: number;
  paySystemState: number;
  printerState: number;
  quantityState: number;
  switchs: number;
  upsState: number;
  valueState: number;
};

export type IEquipmentLanguage = {
  eid: number;
  gid: number;
  langid: number;
  langidx: number;
};

export type IEquipmentCurrency = {
  cid: number;
  eid: number;
  gid: number;
  maxAmount: number;
  minAmount: number;
};

export type IPeriodAmount = {
  amount?: number;
  amountBilled?: number;
  cid?: 1;
  envTotals?: number;
};


export type IEquipmentDetails = {
  equipment: IEquipment;
  equipmentState: IEquipmentState;
  lastComDt: Date;
  lastLocalIp: string;
  lastOperation: string;
  lastOperationDt: Date;
  period: number;
  periodAmounts?: IPeriodAmount[];
  softwareVersion: string;
};

export type IEquipmentOption = {
  eid: number;
  label: string;
};

export type IEquipmentDeviceStatus = {
  id: string;
  idLangKey: string;
  level: string;
  statusLangKey: string;
};

export type IHelpdeskEquipmentDetails = {
  agreeId: number;
  agreeState: number;
  eid: number;
  entity: IEntity; 
  gid: number;
  initAccepted: boolean;
  licenceExp: string;
  maintEntity: IEntity;
  maintEntity2: IEntity;
  sid: number;
  sidDescription: string;
  sn: string;
  softwareVersion: string;
  status: string;
  type: number;
};

export interface IEquipmentStatusResponse extends ApiResponse {
  status: {
    inService: boolean;
    lastComDt: Date;
    lastLocalIp: string;
    lastOperation: string;
    lastOperationDt: Date;
    softwareVersion: string;
    status: number;
  };
  statusList: IEquipmentDeviceStatus[];
}

export interface IEquipmentListResponse extends ApiResponse {
  equipments: IEquipmentDetails[];
  type: string;
  totalRows: number;
}

export interface IEquipmentOptionsResponse extends ApiResponse {
  list: IEquipmentOption[];
  type: string;
}

export interface IHelpdeskEquipmentResponse extends ApiResponse {
  list: IHelpdeskEquipmentDetails[];
  totalRows: number;
  type: string;
}

export const fetchEquipmentList = async (
  entid: number,
  sid: number,
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController
) => {
  const { data } = await axios.get(
    `${EQUIP_BASE_URL}/details/?entid=${entid}&sid=${sid}&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );

  return data;
};

export const fetchHelpdeskEquipmentList = async (
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController
) => {
  const { data } = await axios.get(
    `${HELPDESK_EQUIP_BASE_URL}/details/?offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );

  return data;
};

export const fetchEquipmentByEid = async (eid: number) => {
  const { data } = await axios.get(`${EQUIP_BASE_URL}/status?eid=${eid}`);

  return data;
};

export const fetchEquipmentOptions = async (entid: number, sid: number, controller: AbortController) => {
  const { data } = await axios.get(`${EQUIP_BASE_URL}/options/?entid=${entid}&sid=${sid}`, {
    signal: controller.signal
  });

  return data;
};

export const fetchExistencesReport = async (eid: number, period: number, controller: AbortController) => {
  try {
    const response = await axios.get(`${EQUIP_BASE_URL}/details/report/existences/?eid=${eid}&period=${period}`, {
      signal: controller.signal
    });

    handleDownload(response.data.report, response.data.name, response.data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};
